:root {
  /* https://colorhunt.co/palette/8283 */
  --primary-color: #79a8a9;
  --primary-color-lightest: #f4f7f7;
  --primary-color-light: #aacfd0;
  --primary-color-dark: #5A7C7D; /* was 1f4e5f; */
  --primary-color-darker: #314447; /* ook zelf toegevoegd */
  --secondary-color: #cf1b1b; /* https://colorhunt.co/palette/196154 900d0d, ffdbc5 */
  --h5-grey: #929292;
  --image-background-color: rgba(0, 0, 0, 0.13);
  --image-background-color-dark: rgba(255, 255, 255, 0.13);
  --main-content-margin-left-desktop: 100px;

  /* prachtig nieuw palet gegenereerd door deze website: */
  /* https://mycolor.space/?hex=%2379A8A9&sub=1 */
  --gradient-to-yellow-1: #73BDAF;
  --gradient-to-yellow-2: #7CD0AA;
  --gradient-to-yellow-3: #98E19A;
  --gradient-to-yellow-4: #C3EF85;
  --gradient-to-yellow-5: #F9F871;
  --gradient-to-red-1: #6799AC;
  --gradient-to-red-2: #6788AB;
  --gradient-to-red-3: #76739E;
  --gradient-to-red-4: #865D85;
  --gradient-to-red-5: #8D4960;
  --primary-color-dark-new: #467475;
  --color-spot-light-blue: #BEFCFD;
  --color-spot-dark-red: #891E00;

  --main-font-size: 17px;
  --article-font-size: 19px;
  --h0-font-size: 101px;
  --h1-font-size: 76px;
  --h1-5-font-size: 57px;
  --h2-font-size: 38px;
  --h3-font-size: 27px;
  --h4-font-size: 20px;
  --label-font-size: 15px;
  --h5-font-size: 16px;
  --quote-font-size: 32px;
  --quote-long-font-size: 24px;
  --default-vertical-margin: 14px;
}

/*mix-blend-mode: difference;*/

/**
  This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers.
  You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact
  Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/

@font-face {
  font-family: "Helvetica Neue LT W05";
  font-weight: 300;
  src:
    url("/neue-helvetica/300-light.woff2") format("woff2"),
    url("/neue-helvetica/300-light.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT W05";
  font-weight: 300;
  font-style: italic;
  src:
    url("/neue-helvetica/300-light-italic.woff2") format("woff2"),
    url("/neue-helvetica/300-light-italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT W05";
  font-weight: 400;
  src:
    url("/neue-helvetica/400-roman.woff2") format("woff2"),
    url("/neue-helvetica/400-roman.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT W05";
  font-weight: 400;
  font-style: italic;
  src:
    url("/neue-helvetica/400-italic.woff2") format("woff2"),
    url("/neue-helvetica/400-italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT W05";
  font-weight: 700;
  src:
    url("/neue-helvetica/700-bold.woff2") format("woff2"),
    url("/neue-helvetica/700-bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT W05";
  font-weight: 700;
  font-style: italic;
  src:
    url("/neue-helvetica/700-bold-italic.woff2") format("woff2"),
    url("/neue-helvetica/700-bold-italic.woff") format("woff");
  font-display: swap;
}

html {
  font-family:
    "Helvetica Neue LT W05", "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: var(--main-font-size);
  line-height: 1.1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

body {
  transition: transform 400ms, height 400ms;
  /*box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);*/
  box-shadow:
    0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
  /*box-shadow: none;*/
  /*outline: 1px solid var(--primary-color);*/
  z-index: 0;
  transform-origin: top center;
}

html.menu-open body {
  /* op mobiel: x -30vw; op desktop: -25; */
  transform: translateX(-25vw) translateY(80px) scale(0.4);
}

nav.menu {
  transition: transform 1ms 500ms;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform-origin: top center;
  transform: translateX(-100vw);
}

html.menu-open nav.menu {
  transition: transform 1ms;
  /* alles x 2,5 (transforms x -2,5) */
  transform: translateX(62.5vw) translateY(-200px) scale(2.5);
}

nav.menu .container {
  display: flex;
  justify-content: flex-end;
}

nav.menu .menu-subsection {
  margin-top: var(--h2-font-size);
}

nav.menu .menu-subsection.bijmenu {
  margin-top: var(--h1-font-size);
}

@media (max-width: 799px) {
  nav.menu .menu-subsection.bijmenu {
    display: none;
  }
}

nav.menu .links {
  text-align: right;
}

nav.menu .links a {
  display: block;
}

nav.menu .links a.active {
  color: black;
}

nav.menu .links .bijmenu-item {
  font-weight: inherit;
  color: black;
}

nav.menu .links .bijmenu-item:hover {
  color: var(--primary-color);
}

nav.menu .links .bijmenu-item.active {
  font-weight: 700;
}

.invisible-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 2;
}

html.menu-open .invisible-overlay {
  display: block;
}

*, *::before, *::after {
  box-sizing: border-box;
}

::selection {
  background: var(--secondary-color);
  color: white;
}

.serif {
  font-family:
    "Playfair Display", "Helvetica Neue LT W05", "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*font-style: italic;*/
}

.size-1 {
  font-weight: 700;
  font-size: var(--h1-font-size);
  margin: 12px 0 16px;
  line-height: 0.92;
}

.size-1-5 {
  font-weight: 700;
  font-size: var(--h1-5-font-size);
  margin: 8px 0 12px;
  line-height: 0.92;
}

.size-2 {
  font-weight: 700;
  font-size: var(--h2-font-size);
  margin: 4px 0 8px;
  line-height: 0.95;
}

.size-3 {
  font-weight: 700;
  font-size: var(--h3-font-size);
  margin: 2px 0 6px;
  line-height: 0.95;
}

.size-4 {
  font-weight: 300;
  font-size: var(--h4-font-size);
  margin: 0;
  line-height: 1.1;
}

.size-5 {
  font-weight: 700;
  font-size: var(--h5-font-size);
  margin: 2px 0 4px;
  line-height: 1.1;
  color: var(--h5-grey);
}

.size-p {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

@media (min-width: 370px) {
  .size-1-largephone {
    font-weight: 700;
    font-size: var(--h1-font-size);
    margin: 12px 0 16px;
    line-height: 0.92;
  }

  .size-1-5-largephone {
    font-weight: 700;
    font-size: var(--h1-5-font-size);
    margin: 8px 0 12px;
    line-height: 0.92;
  }

  .size-2-largephone {
    font-weight: 700;
    font-size: var(--h2-font-size);
    margin: 4px 0 8px;
    line-height: 0.95;
  }

  .size-3-largephone {
    font-weight: 700;
    font-size: var(--h3-font-size);
    margin: 2px 0 6px;
    line-height: 0.95;
  }
}

@media (min-width: 550px) {
  .size-0-minitablet {
    font-weight: 700;
    font-size: var(--h0-font-size);
    margin: 16px 0 20px;
    line-height: 0.92;
  }

  .size-1-minitablet {
    font-weight: 700;
    font-size: var(--h1-font-size);
    margin: 12px 0 16px;
    line-height: 0.92;
  }

  .size-1-5-minitablet {
    font-weight: 700;
    font-size: var(--h1-5-font-size);
    margin: 8px 0 12px;
    line-height: 0.92;
  }

  .size-2-minitablet {
    font-weight: 700;
    font-size: var(--h2-font-size);
    margin: 4px 0 8px;
    line-height: 0.95;
  }

  .size-3-minitablet {
    font-weight: 700;
    font-size: var(--h3-font-size);
    margin: 2px 0 6px;
    line-height: 0.95;
  }
}

@media (min-width: 800px) {
  .size-0-desktop {
    font-weight: 700;
    font-size: var(--h0-font-size);
    margin: 16px 0 20px;
    line-height: 0.92;
  }

  .size-1-desktop {
    font-weight: 700;
    font-size: var(--h1-font-size);
    margin: 12px 0 16px;
    line-height: 0.92;
  }

  .size-1-5-desktop {
    font-weight: 700;
    font-size: var(--h1-5-font-size);
    margin: 8px 0 12px;
    line-height: 0.92;
  }

  .size-2-desktop {
    font-weight: 700;
    font-size: var(--h2-font-size);
    margin: 4px 0 8px;
    line-height: 0.95;
  }
}

.label {
  font-weight: 700;
  font-size: var(--label-font-size);
  color: var(--primary-color);
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
}

span.label {
  margin-right: 5px;
}

.label .secondary,
.label em {
  font-weight: 300;
  font-style: normal;
}

p {
  margin: 0;
}

.label + p {
  margin-top: 8px;
}

li {
  margin-bottom: 4px;
}

article p,
article ul,
article ol,
.with-margin {
  margin: 0 0 var(--default-vertical-margin);
}

.with-double-margin {
  margin: 0 0 calc(var(--default-vertical-margin) * 2);
}

article p,
article ul,
article ol {
  font-size: var(--article-font-size);
  font-weight: 400;
  line-height: 1.3;
}

.extra-vertical-space {
  margin: var(--h3-font-size) 0;
}

a {
  text-decoration: none;
  font-weight: 700;
  color: var(--primary-color);
}

a:hover {
  color: black;
}

a .no-link-style {
  color: black;
  font-weight: 300;
}

article a .no-link-style {
  font-weight: 400;
}

button,
.button {
  border: none;
  display: inline-block;
  background: var(--primary-color);
  color: white;
  padding: 4px 12px 7px;
  min-width: 140px;
  text-align: center;
  font-weight: 300;
  font-size: var(--h4-font-size);
  line-height: 1.1;
}

button,
input[type=submit] {
  cursor: pointer;
  height: 48px;
}

.button.large {
  height: 48px;
  padding: 11px 24px;
}

button.full-width,
.button.full-width {
  display: block;
  width: initial;
  height: 48px;
  line-height: 47px;
  padding-top: 0;
  padding-bottom: 0;
}

button:hover,
.button:hover {
  background: black;
  color: white;
}

@media (min-width: 800px) {
  .button {
    padding-top: 8px;
    padding-bottom: 11px;
  }

  button.full-width,
  .button.full-width {
    display: inline-block;
  }
}

button:focus {
  outline: 1px solid black;
}

a.block-link {
  display: block;
  font-weight: inherit;
  color: black;
  text-decoration: none;
}

a.block-link:hover {
  color: var(--primary-color);
  text-decoration: none;
}

/* dit is nu specifiek voor mailing list, nog verbeteren */
input {
  height: 48px;
  background: var(--primary-color-lightest);
  border: none;
  max-width: 100%;
  padding: 0 0 3px 16px;
  font-weight: 400;
  font-size: var(--h4-font-size);
  border-radius: 0;
  flex: 1 0 400px;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

input:focus {
  outline: none;
}

.input-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px auto 12px;
  max-width: 600px;
}

.input-group + .input-group {
  margin-top: -12px;
}

.input-group input,
.input-group button {
  margin: 8px 0;
}

/* mailchimp logo */
.refferal_badge {
  margin: 8px 0;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  padding: 12px;
}

@media (min-width: 500px) {
  .container {
    padding: 12px 16px;
  }
}

header {
  position: relative;
  background: white;
}

header.dark {
  background: black;
  color: white;
}

header a {
  color: black;
}

header.dark a {
  color: white;
}

header .container {
  display: flex;
  justify-content: space-between;
}

.site-title:hover {
  color: black;
}

main {
  position: relative;
  background: white;
}

.link-list {
  list-style: none;
  padding-left: 0;
  margin-top: -6px;
}

.link-list li {
  margin-bottom: 0;
}

.link-list a {
  display: block;
  padding: 6px 0;
  margin: 0;
}

.breadcrumb {
  height: 0;
  overflow: visible;
  position: relative;
  top: 8px;
}

@media (min-width: 700px) {
  .breadcrumb.with-margin-left {
    margin-left: var(--main-content-margin-left-desktop);
  }
}

.page-message {
  display: block;
  color: white;
  border-top: var(--h2-font-size) solid white; /* hide menu artefact */
  margin-bottom: var(--h2-font-size);
  font-weight: inherit;
  padding: 4px 0 8px;
  text-align: center;
}

.page-message:hover {
  color:  white;
}

.page-message.announcement {
  background: var(--gradient-to-red-5);
}

.page-message .button {
  background: white;
  color: black;
}

.content-item:not(.items-belong-together *),
.content-items-group.items-belong-together {
  margin-top: var(--h2-font-size);
  margin-bottom: var(--h1-font-size);
}

.content-items-group {
  margin-left: -12px;
  margin-right: -12px;
}

.content-items-group.align-right {
  justify-content: flex-end;
}

.content-items-group .content-item {
  margin-left: 12px;
  margin-right: 12px;
}

@media (min-width: 500px) {
  .content-items-group {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
  }

  .content-items-group .content-item {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 424px) {
  .content-items-group .content-item.desktop-placement-half {
    flex: 1 0 400px;
    max-width: 600px;
  }
}

.content-item > :first-child {
  margin-top: 0;
}

.content-item.main-content a:not(.block-link):hover {
  color: black;
}

.content-item.desktop-placement-full {

}

.content-item.desktop-placement-left {
  max-width: 662px;
}

.content-item.desktop-placement-right {
  max-width: 652px;
  margin-left: auto;
  margin-right: 0;
}

.content-item.main-content.desktop-placement-left {
  max-width: 820px;
}

.content-item.main-content.desktop-placement-right {
  max-width: 820px;
}

.content-item.alignment-fix-for-odd-number-of-ankeilers {
  margin-top: 0;
  margin-bottom: 0;
}

.content-items-descriptor {
  margin-top: var(--h2-font-size);
}

.content-items-descriptor + .content-items-group {
  margin-top: 0;

}

.content-item .content-item {
  margin-bottom: var(--h2-font-size);
}

.content-item-picture {
  display: block;
  position: relative;
  margin: 0 0 16px;
}

.content-item-picture img {
  width: 100%;
  background: var(--image-background-color);
}

.ratio {
  height: 0;
}

.ratio-1-1 {
  height: 0;
  padding-bottom: 100%;
}

.ratio-3-2 {
  height: 0;
  padding-bottom: 66.667%;
}

.ratio-16-9 {
  height: 0;
  padding-bottom: 56.25%;
}

.ratio img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.content-item.main-content .content-item-picture {
  margin-bottom: var(--h1-font-size);
}

.content-item-text {
  max-width: 420px;
}

@media (min-width: 700px) {
  .content-item.main-content > :not(.content-item-picture) {
    margin-left: var(--main-content-margin-left-desktop);
  }
}

article .content-item-text {
  max-width: 800px;
}

@media (min-width: 1100px) {
  .events-list-layout {
    display: flex;
    flex-wrap: wrap;
  }
}

.events-list-heading {
  flex: 1 0 0;
  max-width: 100%;
  margin-bottom: var(--h2-font-size);
}

@media (max-width: 1099px) {
  .events-sublist-link {
    display: inline;
  }

  .events-sublist-link:not(:first-child)::before {
    content: ' | ';
    font-weight: 400;
  }
}

.events-list {
  flex: 2 0 0;
  max-width: 100%;
  margin-top: -3px;
}

.events-list-year {
  margin: 18px 0 -18px;
}

.event-item {
  margin: 32px 0;
}

.events-list .event-item:last-child {
  border: none;
}

.event-item.primary {
  background: var(--primary-color-lightest);
  border: 1px solid var(--primary-color);
  border-width: 1px 0;
  margin-left: -12px;
  margin-right: -12px;
  padding: 6px 12px 18px;
}

.event-date {
  margin-top: 12px;
}

@media (min-width: 550px) {
  .event-item.primary {
    padding-top: 18px;
  }

  .event-date {
    margin-top: 1px;
  }
}

.event-item.primary .button {
  background: var(--primary-color-dark);
}

.event-item.primary .button:Hover {
  background: black;
}

.event-item.cancelled .event-date {
  text-decoration: line-through;
}

.event-cta {
  margin-top: 5px;
}

@media (min-width: 800px) {
  .event-item {
    display: flex;
  }

  .event-date {
    flex: 1.1 0 0;
  }

  .event-info {
    flex: 2 0 0;
  }

  .event-cta {
    flex: 1 0 0;
    margin-top: 7px;
    text-align: right;
  }
}

.listen-section {
  background: black;
  color: white;
  margin-bottom: var(--h4-font-size);
}

.listen-section.compact {
  margin-bottom: 0;
}

.listen-section a {
  color: white;
  font-weight: inherit;
}

.listen-section a:hover {
  color: var(--primary-color);
}

.content-item.listen-list-heading {
  margin-bottom: 0;
}

.listen-list-scroll {
  overflow-x: auto;
}

.listen-list-layout {
  margin-top: 0;
  display: flex;
}

.listen-section.compact .listen-list-layout {
  margin-top: var(--h2-font-size);
  margin-bottom: var(--h2-font-size);
}

@media (min-width: 800px) {
  .listen-section.compact .listen-list-layout {
    margin-top: var(--h1-font-size);
    margin-bottom: var(--h1-font-size);
  }
}

.listen-list-layout::after {
  content: '';
  display: block;
  width: 300px;
  /* voor grote schermen (breder dan max container breedte): rechter marge gelijktrekken met
  de linker auto-marge */
  border-left: calc((100vw - 1180px) / 2) solid transparent;
  margin-left: -4px; /* proefondervindelijke correctie op bovenstaande border-left */
}

.listen-list {
  margin-top: 12px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 280px;
  grid-gap: 16px;
}

.listen-item-image {
  margin: 0 0 20px;
  width: 260px;
  height: 0;
  padding-bottom: 260px;
  position: relative;
}

.listen-item-image img {
  object-fit: cover;
  object-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--image-background-color-dark);
}

footer {
  background: white;
  position: relative;
}

.footer-container {
  padding-top: 40px;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-item.mailing-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.revue-form-footer {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 500px;
}

.footer-column + .footer-column {
  margin-top: 24px;
}

.social-links .label {
  margin-bottom: 12px;
}

.colofon p {
  margin-top: 2px;
}

@media (max-width: 600px) {
  .footer-secondary-container {
    margin: 0 0 24px;
  }
}

@media (min-width: 700px) {
  .footer-secondary-container {
    display: flex;
  }

  .footer-column {
    flex: 1 0 300px;
  }

  .footer-column + .footer-column {
    margin-top: 0;
  }

  .colofon p {
    margin-top: 10px;
  }
}

.icon-item + .icon-item {
  margin-left: 8px;
}

i.social-media-icon {
  text-align: center;
  font-size: 20px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--primary-color);
  color: white;
}

a:hover .social-media-icon.fa-spotify {
  background: #1DB954;
}

a:hover .social-media-icon.fa-itunes-note {
  background: linear-gradient(to bottom, #f94c57, #fc3c44);
}

.social-media-icon.fa-itunes-note::after {
  content: '';
  display: inline-block;
  width: 2px;
}

a:hover .social-media-icon.fa-youtube {
  background: #ff0000;
}

a:hover .social-media-icon.fa-instagram {
  background: #f09433;
  background: radial-gradient(
    circle at 33% 100%,
    #fed373 4%,
    #f15245 30%,
    #d92e7f 62%,
    #9b36b7 85%,
    #515ecf
  );
/*  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;*/
}

a:hover .social-media-icon.fa-facebook-square {
  background: #3b5998;
}

a:hover .social-media-icon.fa-twitter {
  background: #1da1f2;
}

.gallery {
  margin: -4px;
  margin-top: var(--h1-font-size);
}

.gallery-row {
  display: flex;
}

.gallery-row a {
  display: block;
  margin: 4px;
  position: relative;
}

/* invariant for the gallery items:; flex-grow * padding-bottom = 140 */

.gallery-row a.portrait {
  flex: 1 0 0;
}
.gallery-row a.portrait .image-box {
  padding-bottom: 140%;
}

.gallery-row a.vertical {
  flex: 1.25 0 0;
}
.gallery-row a.vertical .image-box {
  padding-bottom: 112%;
}

.gallery-row a.square {
  flex: 1.4 0 0;
}
.gallery-row a.square .image-box {
  padding-bottom: 100%;
}

.gallery-row a.horizontal {
  flex: 1.66667 0 0;
}
.gallery-row a.horizontal .image-box {
  padding-bottom: 84%;
}

.gallery-row a.landscape {
  flex: 2 0 0;
}
.gallery-row a.landscape .image-box {
  padding-bottom: 70%;
}

/* 16:9 */
.gallery-row a.wide {
  flex: 2.5 0 0;
}
.gallery-row a.wide .image-box {
  padding-bottom: 56%;
}

/* 21:9 */
.gallery-row a.ultrawide {
  flex: 3.2667 0 0;
}
.gallery-row a.ultrawide .image-box {
  padding-bottom: 42.857%;
}

.gallery img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 18%;
  background: var(--image-background-color);
}

.works-list-item {
  margin: var(--h2-font-size) 0;
}

.works-list-item strong {
  color: var(--primary-color);
}

.work-details {
  margin: var(--h2-font-size) 0 0;
}

.detail-item {
  margin: var(--h3-font-size) 0;
}

.simple-quote-list {
  padding: 0 min(8vw, 32px);
}

.simple-quote {
}

.simple-quote + .simple-quote {
  margin-top: var(--h2-font-size);
}

.quote-text {
  position: relative;
  font-family:
    "Playfair Display", "Helvetica Neue LT W05", "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: var(--quote-font-size);
  line-height: 1.2;
  margin: 0;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.quote-text.long-quote {
  font-size: var(--quote-long-font-size);
}

.quote-text::before,
.quote-text::after {
}

.quote-text::before {
  content: '„';
  margin-left: -4px;
}

html[lang=en] .quote-text::before {
  content: '“';
}

.quote-text::after {
  content: '”';
  margin-right: -4px;
}

.quote-attribution {
  text-align: center;
  margin-top: 12px;
}

.quote-attribution::before {
  content: '―';
  display: inline-block;
  width: 13px;
  transform: translateX(-12px);
}

.quote-link {
  text-align: center;
  margin-top: 3px;
}

.quote-link-to-press-page {
  text-align: center;
  margin-top: 14px;
}



/* https://jonsuh.com/hamburgers/ */

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0 -6px;
  padding: 15px 6px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 3px;
  background-color: black;
}

.hamburger-inner:after, .hamburger-inner:before {
  display: block;
  content: "";
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
  background-color: black;
}

.hamburger--squeeze .hamburger-inner {
  transition-timing-function: cubic-bezier(.55,.055,.675,.19);
  transition-duration: 75ms;
}

.hamburger--squeeze .hamburger-inner:before {
  transition: top 75ms ease .12s,opacity 75ms ease;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215,.61,.355,1);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  transition: top 75ms ease,opacity 75ms ease .12s;
  opacity: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
  transform: rotate(-90deg);
}
